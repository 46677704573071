// import { useState } from "react";
import algoliasearch from "algoliasearch/lite";
import CloseButton from 'react-bootstrap/CloseButton';
// import { db } from "./utils/firebase";
// import { doc, getDoc, getDocs, collection, query, where } from "firebase/firestore";

import {
	Col,
	Row,
	Container,
	Button
} from "react-bootstrap";

// Include only the reset
// import 'instantsearch.css/themes/reset.css';
import {
	Hits,
	Index,
	InstantSearch,
	SearchBox,
} from "react-instantsearch-hooks-web";

// import { InstantSearch, Index, SearchBox, Hits } from "react-instantsearch-dom";
import "./index.css";
import "./reset.css";
import ContactHit from "./FullpageSearchComponents/ContactHit"
// or include the full Satellite theme
// import 'instantsearch.css/themes/satellite.css';
const searchClient = algoliasearch("S135G4UNXA", "c617f2ba5405f9cb26591b118f33bb22");

function FullpageSearch({handleClose}) {

	return (
		<Container fluid className="indikativ p-0 m-0">
			<Row className="mx-1 pt-3">
				<InstantSearch indexName="projektek" searchClient={searchClient}>
					<Col sm={12} className=" p-0 mb-3  d-flex justify-content-between">
						<SearchBox
							autoFocus
							placeholder="Ügyfelek keresése.."
							className="w-100 mx-2"
							classNames={{
								form: "input-group input-group-lg",
								input: "form-control",
								submit: "input-group-append",
								reset: "input-group-append",
							}}
							submitIconComponent={({ classNames }) => (
								<Button variant="outline-secondary" size="lg" className={classNames.submit}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										fill="currentColor"
										className="bi bi-search"
										viewBox="0 0 16 16"
									>
										<path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
									</svg>
								</Button>
							)}
							resetIconComponent={({ classNames }) => (
								<Button variant="outline-secondary" size="lg" className={classNames.submit}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										fill="currentColor"
										className="bi bi-x"
										viewBox="0 0 16 16"
									>
										<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
									</svg>
								</Button>
							)}
						/>
						<CloseButton variant="white" onClick={handleClose} className="mt-3 me-3" />
					</Col>
					<Col>
						<Index indexName="ajanlatkeszitoDealKereses">
							<Hits hitComponent={ContactHit} />
						</Index>
					</Col>
					{/* <Col>
						<Index indexName="projektek">
							<h2>
								<code>Projektek: </code>
							</h2>
							<Hits hitComponent={DealHit} />
						</Index>
					</Col> */}
				</InstantSearch>
			</Row>
		</Container>
	);
}

export default FullpageSearch;
