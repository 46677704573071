import firebase from 'firebase/compat/app';
import { getFirestore } from "firebase/firestore"
import 'firebase/compat/auth';

firebase.initializeApp({
    apiKey: "AIzaSyAjEUjY9rJN0wPD9Xo8mylU16NZJxTaGuU",
    authDomain: "greengeo-hub.firebaseapp.com",
    projectId: "greengeo-hub",
    storageBucket: "greengeo-hub.appspot.com",
    messagingSenderId: "794355667146",
    appId: "1:794355667146:web:18aa736de85556c8716433",
    measurementId: "G-ZWDW8MBV49"
});

const db = getFirestore();
const auth = firebase.auth()

export {db, auth};