import { useState } from "react";
import { Col, Row, ListGroup, Badge, Button, Spinner, Nav } from "react-bootstrap";
import { db } from "../utils/firebase";
import { getDocs, collection, query, where } from "firebase/firestore";

import AjanlatComponent from "./AjanlatComponent.js";
import UjAjanlatGomb from "./UjAjanlatGomb";

const ProjektComponent = ({ projekt, index, hit }) => {
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(false);
	const [dealAjanlatok, setDealAjanlatok] = useState([]);

	const getAjanlatok = async (event) => {
		// ezt át kellene írni, hogy ne email, hanem deal alapján adja vissza az ajánlatokat
		// https://us-central1-greengeo-hub.cloudfunctions.net/getContactAjanlatokEssential
		// de addig is:
		setLoading(true);
		// console.log("event", event);
		const DEALID = parseInt(event.target.attributes.crm_deal_id.value);
		console.log("crm_deal_id:", DEALID);

		if (dealAjanlatok.length === 0) {
			try {
				const ajanlatokRef = collection(db, "ajanlat");
				const ajanlatokQuery = query(
					ajanlatokRef,
					where("ajanlatMetadata.crm_deal_id", "==", DEALID)
				);

				let ajanlatok = [];

				const querySnapshot = await getDocs(ajanlatokQuery);
				querySnapshot.forEach((doc) => ajanlatok.push(doc.data()));

				setDealAjanlatok(ajanlatok);
				setChecked((prevChecked) => !prevChecked);
				setLoading(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
			}
		} else {
			setDealAjanlatok([]);
			setChecked((prevChecked) => !prevChecked);
			setLoading(false);
		}
		// document.querySelector("#ujAjanlat" + DEALID).classList.toggle("invisible");
	};

	return (
		<ListGroup.Item
			// as="li"
			className="text-white rounded-0"
			style={{ backgroundColor: "#2d333b" }}
			key={index}
			dataindex={index}
		>
			<Row className="p-0">
				<Col sm={5} className="p-0 pe-2">
					<div className="mb-2 fw-bold">
						<Nav.Link
							href={`https://greengeo.myfreshworks.com/crm/sales/deals/${projekt.crm_deal_id}`}
							target={"_blank"}
							className="d-flex inline float-end"
						>
							<Badge bg="warning">{projekt.crm_deal_id}</Badge>
						</Nav.Link>
						<span>{projekt.name}</span>
					</div>
					<div>
						{/* <small>Összeg:</small>
						<br /> */}
						<strong>{projekt.amount} Ft</strong>
					</div>
					<div>
						{/* <small>Telepítés helyszíne:</small>
						<br /> */}
						{projekt.cf_deal_zipcode} {projekt.cf_deal_city} {projekt.cf_deal_address}
					</div>
				</Col>
				<Col sm={7} className="p-0">
					<Button
						className="text-white card-header"
						style={{ backgroundColor: "#2d333b", width: "100%" }}
						crm_deal_id={projekt.crm_deal_id}
						onClick={getAjanlatok}
						disabled={loading}
					>
						{loading ? "Loading…" : "Ajánlatok"}
					</Button>

					<ListGroup variant="flush">
						{dealAjanlatok.length === 0 ? (
							loading ? (
								<Spinner animation="border" role="status">
									<span className="visually-hidden">Loading...</span>
								</Spinner>
							) : checked ? (
								<>
									<ListGroup.Item className="text-white">
										Nem található ajánlat ehez a projekthez.
									</ListGroup.Item>
									<UjAjanlatGomb hit={hit} projekt={projekt} checked={checked} />
								</>
							) : (
								<></>
							)
						) : (
							<AjanlatComponent hit={hit} projekt={projekt} dealAjanlatok={dealAjanlatok} />
						)}
					</ListGroup>
				</Col>
			</Row>
			<hr></hr>
		</ListGroup.Item>
	);
};

export default ProjektComponent;
