import { useState } from "react";
import { db } from "../utils/firebase";
import { getDocs, collection } from "firebase/firestore";
import { Highlight } from "react-instantsearch-hooks-web";
import { Card, Nav, Badge, Button, ListGroup, Spinner } from "react-bootstrap";
import ProjektComponent from "./ProjektComponent"

function ContactHit({ hit }) {
	const [ugyfelProjektek, setUgyfelProjektek] = useState([]);
	const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
	const pathArray = hit.path.split("/");
	const path = pathArray[1];

	const getProjektek = async (event) => {
		// ezt át kellene írni, hogy ne email, hanem deal alapján adja vissza az ajánlatokat
		// https://us-central1-greengeo-hub.cloudfunctions.net/getContactAjanlatokEssential
		// de addig is:
		setLoading(true);
		// console.log("event", event);
		const UGYFELID = event.target.attributes.path.value;
		console.log("ugyfelId:", UGYFELID);

		if (ugyfelProjektek.length === 0) {
			try {
				const projektekRef = collection(db, "ugyfelek", UGYFELID, "projektek");

				let projektek = [];

				const querySnapshot = await getDocs(projektekRef);
				querySnapshot.forEach((doc) => {
					let adat = doc.data();
					if(!adat.is_deleted) projektek.push(adat);
				});

				setUgyfelProjektek(projektek);
				// console.log(ajanlatok);
                setChecked(prevChecked => !prevChecked);
				setLoading(false);
			} catch (error) {
				console.log(error);
				setLoading(false);
			}
		} else {
			setUgyfelProjektek([]);
            setChecked(prevChecked => !prevChecked);
			setLoading(false);
		}
		// document.querySelector("#ujProjektEsAjanlat" + path).classList.toggle("invisible");
	};

	return (
		<Card bg={"dark"} text={"white"} className="h-100 shadow">
			{/* <Card.Header className="px-1">
				<span className="d-flex inline float-start gap-4">
					<h6>
						<Highlight attribute="last_name" hit={hit} />{" "}
						<Highlight attribute="first_name" hit={hit} />{" "}
					</h6>
					<Highlight attribute="email" hit={hit} />
				</span>
				<Nav.Link
					href={`https://greengeo.myfreshworks.com/crm/sales/contacts/${hit.crm_contact_id}`}
					target={"_blank"}
					className="d-flex inline float-end"
				>
					<Badge className="m-0">{hit.crm_contact_id}</Badge>
				</Nav.Link>
			</Card.Header> */}
			<Card.Body className="pt-1 px-3">
				<p>email: {hit.email}</p>
				<p>telefon: {hit.mobile_number}</p>
				<Button
					className="text-white card-header d-flex inline justify-content-center"
					style={{ backgroundColor: "#2d333b", width: "100%" }}
					path={path}
					onClick={getProjektek}
					disabled={loading}
				>
					{loading ? "Loading…" : "Projektek"}
				</Button>

				{ugyfelProjektek.length === 0 ? (
                    loading ? (
						<Spinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					) : (
                        checked ? "Nem található Projekt ehhez az ügyfélhez": <></>
					)
				) : (
					<ListGroup variant="flush" className="rounded-0">
						{ugyfelProjektek.map((projekt, index) => (
                            <ProjektComponent projekt={projekt} index={index} hit={hit}/>
						))}

						{/* <ListGroup.Item
                            className="d-flex justify-content-end bg-dark text-white rounded-0 invisible"
                            id={"ujAjanlat" + hit.crm_deal_id}
                        >
                            
                        </ListGroup.Item> */}
					</ListGroup>
				)}
				{checked ? (
					<Button
					variant="outline-primary"
					size="sm"
					className="float-end mt-2"
					id={"ujProjektEsAjanlat" + path}
					onClick={() => {
						window.open(`/?contact_id=${hit.crm_contact_id}`, "_self");
					}}
				>
					Új projekt/ajánlat
				</Button>
				) : <></>}
				
			</Card.Body>
		</Card>
	);
}

export default ContactHit;
