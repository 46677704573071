import { useState, useContext, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import AuthContext from './contexts/AuthContext';
import 'firebase/auth';
import { auth} from './utils/firebase';
import FullpageSearch from './FullpageSearch';

const Login = lazy(() => import('./Login'));
// const Signup = lazy(() => import('./components/Auth/Signup'));
const Home = lazy(() => import('./Home'));

export default function App() {
  const [isAuthenticated, setAuthentication] = useState(useContext(AuthContext));
  if (process.env.NODE_ENV === 'development') console.log('isAuthenticated:', isAuthenticated);
  
  useEffect(() => {
    // Things you should know: auth.onAuthStateChanged follows the observer pattern needs
    // to be unsubscribe on every run. The useEffect hook will check authentication state
    // after rerender. That means everytime the value from AuthContext is updated.
    const unsubscribe = auth.onAuthStateChanged(user => {
      user ? setAuthentication(true) : setAuthentication(false);
      unsubscribe();
    });
  }, []);

  function renderHome() {
    if (isAuthenticated === false) {
      return <Login />;
    } else if (isAuthenticated === true) {
      return <Home />;
    } else {
      return null;
    }
  }

  return (
    <AuthContext.Provider value={[isAuthenticated, setAuthentication]}>
      <Router>
        {/* <Header /> */}
        <Suspense fallback={null}>
          <Routes>
            <Route path='/' element={renderHome()} />
            <Route path='login' element={<Login />} />
            <Route path='home' element={<Home />} />
            <Route path='search' element={<FullpageSearch />} />
            <Route path="*" element={<Navigate to="/"/>} />
          </Routes>
        </Suspense>
        <Toaster
          gutter={16}
          position="bottom-right"
          toastOptions={{ className: "styles.toast" }}
          containerStyle={{ top: 88 }}
        />
      </Router>
    </AuthContext.Provider>
  );
}
