import { ListGroup, Nav} from "react-bootstrap";
import UjAjanlatGomb from "./UjAjanlatGomb";

const AjanlatComponent = ({hit, projekt, dealAjanlatok }) => {

	return (
		<>
			{dealAjanlatok.map((ajanlat, index) => (
				<ListGroup.Item
					className="d-flex justify-content-between align-items-start text-white "
					key={index}
					dataindex={index}
				>
					<div className="ms-2 me-auto">
						<div className="fw-bold">
							{ajanlat.panel.darabszam}db | {ajanlat.teljesitmenyPeak / 1000}kWp -{" "}
							<strong>{ajanlat.ajanlatTipus}</strong>
						</div>
						{ajanlat.inverter.optimum.megjelenitesiNev}
						<p>
							Netto:{" "}
							<strong>
								{ajanlat.ajanlatTipus === "vegleges"
									? Math.round(ajanlat.nettoArak.szum.kedvezmenyes)
									: Math.round(ajanlat.nettoArak.szum.optimum)}
							</strong>{" "}
							Ft | Brutto:{" "}
							<strong>
								{ajanlat.ajanlatTipus === "vegleges"
									? Math.round(ajanlat.nettoArak.szum.kedvezmenyes * 1.27)
									: Math.round(ajanlat.nettoArak.szum.optimum * 1.27)}
							</strong>{" "}
							Ft
						</p>
						{ajanlat.ajanlatMetadata.ajanlatId}
					</div>

					<Nav.Link
						className="text-decoration-none text-white"
						href={`https://drive.google.com/file/d/${ajanlat.pdf.drive.id}`}
						target="_blank"
					>
						📂
					</Nav.Link>

					<Nav.Link
						className="text-decoration-none text-white"
						href={`/?ajanlatId=${ajanlat.ajanlatMetadata.ajanlatId}`}
						target="_self"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="20"
							height="20"
							fill="currentColor"
							className="bi bi-pencil-square"
							viewBox="0 0 16 16"
						>
							<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"></path>
							<path
								fill-rule="evenodd"
								d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
							></path>
						</svg>
					</Nav.Link>
				</ListGroup.Item>
			))}

			<UjAjanlatGomb hit={hit} projekt={projekt}/>
		</>
	);
};

export default AjanlatComponent;
