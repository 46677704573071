import { ListGroup, Button } from "react-bootstrap";

const UjAjanlatGomb = ({hit, projekt}) => {
	return (
			<ListGroup.Item
				className="d-flex justify-content-end text-white"
				id={"ujAjanlat" + projekt.crm_deal_id}
			>
				<Button
					variant="outline-warning"
					size="sm"
					// className="float-end button-sm"
					onClick={async () => {
						window.open(
							`/?contact_id=${hit.crm_contact_id}&deal_id=${projekt.crm_deal_id}`,
							"_self"
						);
					}}
				>
					Új ajánlat ehhez a projekthez
				</Button>
			</ListGroup.Item>
	); 
};

export default UjAjanlatGomb;
